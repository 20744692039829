import * as React from "react";

import getDeviceWidth from "../../../utils/devicewidth.util";

interface ResponsiveMenuItemProps {
    item: JSX.Element;
    key: React.Key | null;
    dropdownRef: React.RefObject<HTMLDivElement>;
    menuButtonsRef: React.RefObject<HTMLDivElement>;
    hiddenMenuItemCount: number;
}

const ResponsiveMenuItemBase = ({ item, key, dropdownRef, menuButtonsRef, hiddenMenuItemCount }: ResponsiveMenuItemProps): JSX.Element | null => {
    const [currentWindowWidth, setCurrentWindowWidth] = React.useState(0);
    const [isWindowSizeIncreasing, setIsWindowSizeIncreasing] = React.useState(false);
    const [ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);

    const initialElemPostionRef = React.useRef<number>(0);
    const currentWindowWidthRef = React.useRef(currentWindowWidth);
    currentWindowWidthRef.current = currentWindowWidth;

    const componentRef = React.useRef<HTMLDivElement>(null);

    const threshold = 20;

    let overlappingSize = currentWindowWidth - initialElemPostionRef.current - threshold - (menuButtonsRef.current?.offsetWidth || 0);

    if (hiddenMenuItemCount > 0 && !isWindowSizeIncreasing) {
        overlappingSize = overlappingSize - (dropdownRef.current?.offsetWidth || 0);
    }
    if (hiddenMenuItemCount === 1 && isWindowSizeIncreasing) {
        overlappingSize = overlappingSize + (dropdownRef.current?.offsetWidth || 0);
    }
    let timeoutId: number;
    const resizeListener = () => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            const deviceWidth = getDeviceWidth();
            setIsWindowSizeIncreasing(deviceWidth > currentWindowWidthRef.current);
            setCurrentWindowWidth(deviceWidth);
        }, 200);
    };
    React.useEffect(() => {
        resizeListener();
    });
    React.useEffect(() => {
        window.addEventListener("resize", resizeListener);

        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, [item]);

    React.useEffect(() => {
        if (componentRef.current && !initialElemPostionRef.current) {
            const { x: xAxis, width: menuItemWidth } = componentRef.current.getBoundingClientRect();
            if (menuItemWidth) {
                const elemPosition = Math.ceil(xAxis + menuItemWidth);
                initialElemPostionRef.current = elemPosition;
                forceUpdate();
            }
        }
    });

    if (overlappingSize > 0) {
        return (
            <span key={key} ref={componentRef}>
                {item}
            </span>
        );
    }
    return <></>;
};

export const ResponsiveMenuItem = ResponsiveMenuItemBase;
