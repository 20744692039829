import * as React from "react";

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink } from "reactstrap";
import { Widget as IWidget, Site, WithId } from "@maxxton/cms-api";
import { Widget, isPageWidget, parseApiWidget, reportWidgetRenderError } from "../../";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Identity } from "../../../containers/Identity";
import { MenuWidgetSpec } from "../../widget";
import { ResponsiveMenuItem } from "./ResponsiveMenuItem";
import { SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { WidgetOptions } from "./";
import { debounce } from "lodash";
import { getI18nLocaleString } from "../../../i18n";
import namespaceList from "../../../i18n/namespaceList";
import { setOpacityOnHide } from "../../../components/utils";

interface ResponsiveMenuProps {
    items: IWidget[];
    className?: string;
    context: CMSProvidedProperties;
    sitemapWidgetOptions?: SitemapPageLinkWidgetOptions;
    sites: Array<Site & WithId>;
    options?: WidgetOptions;
}

// eslint-disable-next-line max-lines-per-function
const ResponsiveMenuViewBase = ({ items, className, context, sitemapWidgetOptions, sites, options }: ResponsiveMenuProps): JSX.Element | null => {
    const { currentLocale, site } = context;

    const [menuItems, setMenuItems] = React.useState<JSX.Element[]>([]);
    const [menuButtons, setMenuButtons] = React.useState<JSX.Element[]>([]);
    const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);
    const [hiddenMenuItemCount, setHiddenMenuItemCount] = React.useState<number>(0);
    const [loading, setLoading] = React.useState<boolean>(true);

    const menuContainerRef = React.useRef<HTMLUListElement>(null);
    const dropdownRef = React.useRef<HTMLDivElement>(null);
    const menuButtonsRef = React.useRef<HTMLDivElement>(null);
    const hideWidget = setOpacityOnHide(options);
    React.useEffect(() => {
        if (items.length) {
            const updatedMenuBtns: IWidget[] = [];
            const updatedMenuItems = items.filter((menuItem) => {
                if (menuItem.options.useAsButton || menuItem.options.useScrollTop) {
                    updatedMenuBtns.push(menuItem);
                    return false;
                }
                return true;
            });
            if (updatedMenuItems.length) {
                setLoading(false);
            }
            handleMenuElements(updatedMenuItems);
            handleMenuBtnElements(updatedMenuBtns);
            setHiddenMenuItemCount(updatedMenuItems.length);
        }
    }, [items]);

    React.useEffect(() => {
        const observer = new MutationObserver((entries) => {
            if (entries[0].removedNodes.length) {
                setHiddenMenuItemCount((hiddenMenuItemCount) => hiddenMenuItemCount + entries[0].removedNodes.length);
            }
            if (entries[0].addedNodes.length) {
                setHiddenMenuItemCount((hiddenMenuItemCount) => {
                    if (hiddenMenuItemCount > 0) {
                        return hiddenMenuItemCount - entries[0].addedNodes.length;
                    }
                    return 0;
                });
            }
        });
        observer.disconnect();
        if (menuContainerRef.current) {
            observer.observe(menuContainerRef.current, { childList: true });
        }
        return () => observer.disconnect();
    }, [menuItems, hiddenMenuItemCount]);

    React.useEffect(() => {
        const menuElements = document.querySelectorAll(".inline-nav-menu-item");
        const updateDebouncedMenuElement = debounce(closeMenuDelayed, 100);

        menuElements.forEach((element) => {
            element.children[0].addEventListener("click", updateDebouncedMenuElement);
        });

        return () => {
            menuElements.forEach((element) => {
                element.children[0].removeEventListener("click", updateDebouncedMenuElement);
            });
        };
    }, [closeMenuDelayed]);

    function closeMenuDelayed() {
        setDropdownOpen(false);
    }

    const generateMenuElements = async (menuData: IWidget[]) => {
        const pItems = menuData.map(async (menuItem, index) => {
            const menuWidget: Widget<WidgetOptions> = await parseApiWidget(menuItem, context);
            const childSpec = menuWidget.spec;
            if (isPageWidget(childSpec)) {
                return childSpec
                    .render(menuWidget, context, sitemapWidgetOptions, undefined, undefined)
                    .then((elem) => <Identity key={menuWidget._id}>{elem}</Identity>)
                    .catch((err) => {
                        reportWidgetRenderError("ResponsiveMenuWidget", err, childSpec, context);
                        return <div key={index} />;
                    });
            }
            return (childSpec as MenuWidgetSpec<any>)
                .render(menuWidget, context, sites, sitemapWidgetOptions)
                .then((elem) => <Identity key={menuWidget._id}>{elem}</Identity>)
                .catch((err) => {
                    reportWidgetRenderError("ResponsiveMenuWidget", err, childSpec, context);
                    return <div key={index} />;
                });
        });
        const elements = await Promise.all(pItems);
        return elements;
    };

    const handleMenuElements = async (menuData: IWidget[]) => {
        const elements = await generateMenuElements(menuData);
        setMenuItems(elements);
    };

    const handleMenuBtnElements = async (menuData: IWidget[]) => {
        const elements = await generateMenuElements(menuData);
        setMenuButtons(elements);
    };

    function toggleDropdown() {
        setDropdownOpen(!dropdownOpen);
    }

    const renderLoadingMenu = () => (
        <div className={`loading-menu responsive-menu-wrapper ${className} ${hideWidget}`}>
            <div className={"responsive-menu responsive-menu-loader"}>
                <div className="responsive-menu-items">
                    {items?.map((navItem, index) => {
                        const allLabel = navItem.options.localizedLabel || [];
                        const localeIndex = allLabel.findIndex((e: any) => e.locale === currentLocale.locale);
                        const navLabel = allLabel[localeIndex];
                        return (
                            navLabel?.label && (
                                <NavItem className="inline-nav-menu" key={index}>
                                    <NavLink>{navLabel.label}</NavLink>
                                </NavItem>
                            )
                        );
                    })}
                </div>
            </div>
        </div>
    );

    const renderDropdown = () => {
        if (hiddenMenuItemCount > 0) {
            const hiddenMenuItems = menuItems.slice(-hiddenMenuItemCount);
            return (
                <div ref={dropdownRef} className="responsive-menu-dropdown">
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle caret>{getI18nLocaleString(namespaceList.admin, "more", currentLocale, site)}</DropdownToggle>
                        <DropdownMenu right>
                            {hiddenMenuItems.map((elem, index) => (
                                <DropdownItem key={index}>{elem}</DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            );
        }
    };

    return (
        <>
            {loading ? (
                renderLoadingMenu()
            ) : (
                <div className={`responsive-menu-wrapper ${className}`}>
                    <div className={"responsive-menu"}>
                        <ul className="responsive-menu-items" ref={menuContainerRef}>
                            {menuItems.length &&
                                menuItems.map((elem) => (
                                    <ResponsiveMenuItem key={elem.key} item={elem} dropdownRef={dropdownRef} menuButtonsRef={menuButtonsRef} hiddenMenuItemCount={hiddenMenuItemCount} />
                                ))}
                        </ul>
                        {renderDropdown()}
                    </div>
                    <div className="responsive-menu-buttons" ref={menuButtonsRef}>
                        {menuButtons}
                    </div>
                </div>
            )}
        </>
    );
};

export const ResponsiveMenuView = ResponsiveMenuViewBase;
