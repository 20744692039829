import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { Widget as IWidget, LocalizedContentBase, Menu, Site, WithId } from "@maxxton/cms-api";
import { MenuWidgetSpec, reportWidgetRenderError } from "../../widget";
import { PageWidgetSpec, Widget, isPageWidget, parseApiWidget } from "../../";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Identity } from "../../../containers/Identity";
import { MenuView } from "./Menu";
import { ResponsiveMenuView } from "./ResponsiveMenu";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { menuSpec } from "../../../form-specs/models/menu";
import namespaceList from "../../../i18n/namespaceList";

export interface LocalizedMenuId extends LocalizedContentBase {
    menuIdLocalized: string | null;
}

export interface WidgetOptions {
    styleIds: any[];
    menuId: string | null;
    localized: LocalizedMenuId[];
    useDifferentMenuPerLanguage: boolean;
}

const TARGETS = ["menu", "menu-view"];

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "menu-widget-options",
    name: getI18nLocaleObject(namespaceList.pluginMenu, "menuViewOptions"),
    pluralName: getI18nLocaleObject(namespaceList.pluginMenu, "menuViewOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "general"),
                    properties: [
                        [
                            {
                                type: "checkbox",
                                variable: "useDifferentMenuPerLanguage",
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "useDifferentMenuPerLanguage"),
                            },
                            localized({
                                variable: "localized",
                                tabContent: [
                                    {
                                        variable: "menuIdLocalized",
                                        label: getI18nLocaleObject(namespaceList.pluginMenu, "menu"),
                                        type: "autocomplete",
                                        isClearable: false,
                                        refType: menuSpec,
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.useDifferentMenuPerLanguage,
                            }),
                            {
                                variable: "menuId",
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "menu"),
                                type: "autocomplete",
                                refType: menuSpec,
                                visible: (options: WidgetOptions) => !options.useDifferentMenuPerLanguage,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetWebContent, "styling"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
            ],
        },
    ],
};

export const menuViewWidget: PageWidgetSpec<WidgetOptions> = {
    id: "menu",
    type: "page",
    widgetGroup: WidgetGroup.CONTENT,
    name: getI18nLocaleObject(namespaceList.pluginMenu, "menuView"),
    description: getI18nLocaleObject(namespaceList.pluginMenu, "menuViewDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        menuId: null,
        localized: [],
        useDifferentMenuPerLanguage: true,
    }),
    // commented for performance improvement
    // async instances(): Promise<WidgetOptions[]> {
    //     const menus = await MenuApi.find();
    //     return menus.map((menu): WidgetOptions => ({
    //         menuId: menu._id,
    //         styleIds: [],
    //     }));
    // },
    async instanceDescription({ widget, context }): Promise<string> {
        const { currentLocale, site } = context;
        let menuId: string | null = getLocalizedContent({ site, currentLocale, localizedContent: widget.options.localized, keys: ["menuIdLocalized"] })?.menuIdLocalized || null;
        if (menuId == null) {
            // Obtain the non-localized menuId.
            menuId = widget.options.menuId;
        }

        const menu: Menu | null = menuId != null ? await context.cmsApi.menuApi.findById({ id: menuId, projection: { name: 1 } }) : null;
        if (menu == null) {
            return getI18nLocaleString(namespaceList.pluginMenu, "noMenu");
        }
        return menu.name;
    },
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties, sitemapWidgetOptions, resultOptions, dynamicContainerOptions, shouldReturnProps, allSites, flexboxOptions) {
        const { currentLocale, site } = context;
        const localizedMenuId: string | null = getLocalizedContent({ site, currentLocale, localizedContent: widget.options.localized, keys: ["menuIdLocalized"] })?.menuIdLocalized || null;
        const { styleIds, menuId } = widget.options;
        const menu: (Menu & WithId) | null =
            localizedMenuId !== null ? await context.cmsApi.menuApi.findById({ id: localizedMenuId }) : menuId ? await context.cmsApi.menuApi.findById({ id: menuId }) : null;
        let items: Array<JSX.Element | IWidget | null> = [];

        if (menu == null) {
            return <div className="text-center bg-info text-white p-3 mb-4">{getI18nLocaleString(namespaceList.pluginMenu, "noMenuConfigured")}</div>;
        } else if (menu.children.length) {
            const siteIds = findDistinctSiteIdsInMenu(menu?.children);
            const sites = (
                await Promise.all(
                    siteIds.map(async (siteId) => {
                        if (context.site?._id === siteId) {
                            return context.site;
                        }
                        return context.cmsApi.siteApi.findById({ id: siteId, projection: { sitemap: 0 } });
                    })
                )
            ).filter((site) => !!site) as Array<Site & WithId>;

            const menuItems = menu.children.filter((item) => item !== null && !item.options.hidden);
            if (menu.enableResponsiveMenu && !context.device?.isDesktop) {
                items = menuItems;
            } else {
                items = await Promise.all(
                    menuItems.map(async (menuItem) => {
                        const menuWidget: Widget<any> = await parseApiWidget(menuItem, context);
                        const childSpec = menuWidget.spec;
                        if (isPageWidget(childSpec)) {
                            return (
                                childSpec
                                    .render(menuWidget, context, sitemapWidgetOptions, undefined, undefined)
                                    .then((elem) => <Identity key={menuWidget._id}>{elem}</Identity>)
                                    // silent the failure of the widget and report error in logstash
                                    .catch((err) => {
                                        reportWidgetRenderError(widget, err, childSpec, context);
                                        return null;
                                    })
                            );
                        }
                        return (
                            (childSpec as MenuWidgetSpec<any>)
                                .render(menuWidget, context, sites, sitemapWidgetOptions)
                                .then((elem) => <Identity key={menuWidget._id}>{elem}</Identity>)
                                // silent the failure of the widget and report error in logstash
                                .catch((err) => {
                                    reportWidgetRenderError(widget, err, childSpec, context);
                                    return null;
                                })
                        );
                    })
                );
            }

            const className = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds);

            if (menu.enableResponsiveMenu && !context.device?.isDesktop) {
                return <ResponsiveMenuView className={className} items={items as IWidget[]} context={context} sitemapWidgetOptions={sitemapWidgetOptions} sites={sites} options={widget.options} />;
            }
            return <MenuView className={className} items={items as Array<JSX.Element | null>} options={widget.options} />;
        }
        return <div />;
    },
};

function findDistinctSiteIdsInMenu(children: IWidget[]): string[] {
    const siteIds: string[] = [];
    for (const child of children) {
        if (child.type === "pagelink") {
            if (child.options?.siteId) {
                if (!siteIds.includes(child.options.siteId)) {
                    siteIds.push(child.options.siteId);
                }
            }
        } else if (child.children) {
            const childSiteIds = findDistinctSiteIdsInMenu(child.children);
            for (const childSiteId of childSiteIds) {
                if (!siteIds.includes(childSiteId)) {
                    siteIds.push(childSiteId);
                }
            }
        }
    }

    return siteIds;
}
